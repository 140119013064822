import { CalendlyButton } from 'components/ContactUs/components/CalendlyButton'
import { Text } from 'components/Text/Text'
import { ButtonDesign } from 'components/Inputs/Button/Button'
import { Icon, IconColor, IconName, IconType } from 'components/DataDisplay/Icon/Icon'
import { useTranslation } from 'hooks/useTranslation'
import styles from './WhySmarthousing.module.scss'

export const WhySmartHousing = () => {
  const { t } = useTranslation()

  return (
    <div className={`${styles.whySmartHousingContainer} ${styles.container}`}>
      <Text type="h2" weight="bold" align="center">
        {t('home:why-smarthousing.title')}
      </Text>

      <Text type="headline" className={styles.subtitle} align="center">
        {t('home:why-smarthousing.subtitle')}
      </Text>

      <div className={styles.infoContainer}>
        <img src="/images/why-smarthousing.webp" alt={t('home:why-smarthousing.title')} className={styles.image} />
        <div className={styles.info}>
          <Text type="h3" color="red-500" weight="bold">
            {t('home:why-smarthousing.info.title')}
          </Text>

          <Text type="h3" weight="bold" className={styles.infoSubtitle}>
            {t('home:why-smarthousing.info.subtitle')}
          </Text>

          <div className={styles.bulletPointsContainer}>
            <div className={styles.bullet}>
              <Icon name={IconName.HAND_COIN} type={IconType.LINE} color={IconColor.BURGUNDY} size={24} />
              <Text type="headline" weight="bold">
                {t('home:why-smarthousing.info.bullets.first')}
              </Text>
            </div>
            <div className={styles.bullet}>
              <Icon name={IconName.WALLET} type={IconType.LINE} color={IconColor.BURGUNDY} size={24} />
              <Text type="headline" weight="bold">
                {t('home:why-smarthousing.info.bullets.second')}
              </Text>
            </div>
            <div className={styles.bullet}>
              <Icon name={IconName.HAND_HEART} type={IconType.LINE} color={IconColor.BURGUNDY} size={24} />
              <Text type="headline" weight="bold">
                {t('home:why-smarthousing.info.bullets.third')}
              </Text>
            </div>
          </div>

          <Text type="headline">{t('home:why-smarthousing.info.paragraph')}</Text>

          <div className={styles.infoButtonContainer}>
            <CalendlyButton design={ButtonDesign.BLUE} className={styles.infoButton}>
              {t('home:why-smarthousing.action-button')}
            </CalendlyButton>
          </div>
        </div>
      </div>
      <div className={styles.infoButtonTabletContainer}>
        <CalendlyButton design={ButtonDesign.BLUE} className={styles.infoButtonTablet}>
          {t('home:why-smarthousing.action-button')}
        </CalendlyButton>
      </div>
    </div>
  )
}
