import { useFetchPropertiesMostVisited } from 'api/queries/fetchPropertiesMostVisited'
import { ButtonLink, ButtonLinkDesign } from 'components/ButtonLink/ButtonLink'
import { Typography, TypographyType } from 'components/DataDisplay/Typography/Typography'
import { HousingCard } from 'components/HousingCard/HousingCard'
import { useTranslation } from 'hooks/useTranslation'
import { MarketplaceProperty } from 'model/Property'

import styles from './Housing.module.scss'

export const Housing = () => {
  const { t } = useTranslation()
  const { data, status, error } = useFetchPropertiesMostVisited()
  if (status === 'error') {
    return <div>{error}</div>
  }

  return (
    <div className={`${styles.housingContainer} ${styles.containerFluid}`}>
      <div className={styles.container}>
        <Typography typographyType={TypographyType.H2_TITLE} className={styles.title}>
          {t('home:our-houses.title')}
        </Typography>

        <Typography typographyType={TypographyType.PARAGRAPH} className={styles.subtitle}>
          {t('home:our-houses.subtitle')}
        </Typography>
        <div className={styles.cardsContainer}>
          <div className={styles.cardsRow}>
            {data &&
              data.properties &&
              Array.isArray(data.properties) &&
              [...data.properties.slice(0, 2)].map((property: MarketplaceProperty) => <HousingCard key={property.slug} {...property} />)}
          </div>
          <div className={styles.cardsRow}>
            {data &&
              data.properties &&
              Array.isArray(data.properties) &&
              [...data.properties.slice(2, 4)].map((property: MarketplaceProperty) => <HousingCard key={property.slug} {...property} />)}
          </div>
        </div>
        <div>
          <div className={styles.housingButtonWrapper}>
            <ButtonLink href="/properties" className={styles.housingButton} design={ButtonLinkDesign.BLUE}>
              {t('home:our-houses.action-button')}
            </ButtonLink>
          </div>
        </div>
      </div>
    </div>
  )
}
