
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { prefetchProperties } from 'api/queries/fetchProperties'
import { withSSR } from 'lib/ssr/middleware'
import { GetServerSideProps } from 'next'
import { Landing } from '../containers/Landing/Landing'

 const _getServerSideProps: GetServerSideProps = withSSR([], async (ctx, qclient) => {
  await prefetchProperties(qclient, {
    pagination: { limit: 0, offset: 0 },
    areaId: '',
    bounds: [],
  })
  return {
    props: {},
  }
})

const LandingPage = () => {
  return <Landing />
}

export default LandingPage


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  